import React from "react";
import Index from "../layouts";
export default function NotFoundPage() {
  return (
    <Index>
      <span>
        Damn! It seems like you can not find the page 😢 Its okay though,
        because Elon Musk is still alive 🚀
      </span>
    </Index>
  );
}
